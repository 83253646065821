<template>
  <form class="userForm" @submit.prevent="submitPassword">
    <div class="text-end text-light" v-if="view != 'perfil'">
      <button type="button" class="btnEliminarTable" @click="cambioClave">
        X
      </button>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-md-12">
        <label class="text-light">Nueva clave</label>
        <input
          v-model="objUser.password"
          type="password"
          :class="{
            'form-control': true,
          }"
        />
        <small class="form-text txt-red" v-if="v$.password.$error">
          *Debe agregar un password mayor a 4 caracteres.
        </small>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col">
        <button class="btn btn-primary">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import useUsuarios from "../composable/useUsuarios";
import useAuth from "../../../modules/auth/composable/useAuth";

export default {
  props: ["view"],
  components: {},
  setup() {
    const objUser = inject("objUser");
    const store = useStore();
    const btn = inject("btn");
    const { changePassword } = useUsuarios();
    const { logout } = useAuth();
    const resetPass = inject("resetPass");
    objUser.password = "";

    const rules = {
      password: {
        required: required,
        minLength: minLength(4),
      },
    };

    const v$ = useVuelidate(rules, objUser);

    const submitPassword = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return false;
      }
      store.commit("showLoader");
      await changePassword(objUser);
      await logout();
      btn.value = false;
      store.commit("hideLoader");
    };

    return {
      submitPassword,
      objUser,
      v$,
      resetPass,
      cambioClave: () =>( resetPass.value = false),
    };
  },
};
</script>

<style>
</style>