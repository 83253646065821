<template>
  <form class="userForm" @submit.prevent="submitUser" v-if="!resetPass">
    <div class="text-end text-light" v-if="view != 'perfil'">
      <Cerrar />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <img
            :src="imageUrl || 'img/no-img.jpg'"
            alt=""
            id="img"
            class="img-fluid img-user"
            @click="$refs.file.click()"
            style="cursor: pointer"
          />
          <input
            class="d-none"
            ref="file"
            type="file"
            @change="onChange"
            accept=".png, .jpg, .jpeg"
          />
        </div>
      </div>
      <div class="row justify-content-center py-1">
        <div class="col-md-12">
          <label class="text-light">Nombre</label>
          <input
            type="text"
            v-model="objUser.name"
            :class="{
              'form-control': true,
              valid: !v$.$error && v$.$dirty,
              error: v$.$error,
            }"
            id="name"
          />
          <small class="form-text txt-red" v-if="v$.name.$error">
            *Debe agregar un nombre.
          </small>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-md-12">
          <label class="text-light">Email</label>
          <input
            v-model="objUser.email"
            type="text"
            :class="{
              'form-control': true,
              valid: !v$.$error && v$.$dirty,
              error: v$.$error,
            }"
          />
          <small class="form-text txt-red" v-if="v$.email.$error">
            *Debe agregar un email.
          </small>
        </div>
      </div>

      <div
        class="row justify-content-center mt-3"
        v-if="user.role.nombre == 'Administrador'"
      >
        <div class="col-md-12">
          <label class="text-light">Rol</label>
          <select class="form-select" v-model="objUser.role_id">
            <option value="3">Operador</option>
            <option value="2">Supervisor</option>
            <option value="1">Administrador</option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-md-12">
          <label class="text-light">Teléfono</label>
          <input
            v-model="objUser.telefono"
            type="text"
            class="form-control"
            v-maska="'####-###-##-##'"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-3" v-if="!objUser.id">
        <div class="col-md-12">
          <label class="text-light">Clave</label>
          <input
            v-model="objUser.password"
            v-on:keyup="validarPassword"
            type="password"
            :class="{
              'form-control': true,
              valid: !v$.$error && v$.$dirty,
              error: v$.$error,
            }"
          />
          <small class="form-text txt-red" v-if="v$.password.$error">
            *Debe agregar un password.
          </small>
        </div>
      </div>

      <div class="row justify-content-center mt-3" v-if="!objUser.id">
        <div class="col-md-12">
          <label class="text-light">Repetir clave</label>
          <input
            v-model="objUser.password_confirmation"
            v-on:keyup="validarPassword"
            type="password"
            :class="{
              'form-control': true,
              valid: !v$.$error && v$.$dirty,
              error: v$.$error,
            }"
          />
          <small class="form-text txt-red" v-if="!validarPassword()">
            *Los password deben ser iguales.
          </small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <div class="form-check form-switch text-center">
            <input
              v-model="objUser.status"
              class="form-check-input"
              type="checkbox"
              :checked="objUser.status"
            />
            <label class="form-check-label text-light">Activo </label>
          </div>
        </div>
        <div class="col-md-9 text-right" v-if="objUser.id">
          <a class="txt-red" style="cursor: pointer" @click="cambioClave"
            >Cambio de clave</a
          >
        </div>
      </div>

      <div class="row justify-content-center py-2">
        <!-- <div class="col-md-3 mt-2" v-if="view != 'perfil'">
          <button type="button" class="btn btn-danger" @click="limpiarFrm">
            Nuevo
          </button>
        </div> -->
        <div class="col-md-3 mt-2">
          <button class="myButtonAceptar">Guardar</button>
        </div>
      </div>
    </div>
  </form>
  <ChangePassword v-else />
</template>

<script>
import { inject, onBeforeUnmount, provide, ref } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import Cerrar from "../../../components/button/Cerrar.vue";
import ChangePassword from "../components/ChangePassword.vue";
import useAuth from "../../../modules/auth/composable/useAuth";
import useUsuarios from "../composable/useUsuarios";
import Swal from "sweetalert2";
export default {
  props:{
      view: {
      type: String,
      default: ""
    }
  },
  components: {
    Cerrar,
    ChangePassword,
  },
  setup() {
    const objUser = inject("objUser");
    const imageUrl = inject("imageUrl");
    const btn = inject("btn");
    const store = useStore();
    const previewImage = ref("");
    const foto = ref("");
    const image = ref("");
    const resetPass = ref(false);
    const { user } = useAuth();
    const { getUser,createUser,editUser } = useUsuarios();
    const rules = {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required: required,
        minLength: minLength(4),
      },
      password_confirmation: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objUser);
    const submitUser = async () => {
      v$.value.$touch();
      store.commit("showLoader");
      let resp = "";

      if (v$.value.$invalid) {
        return false;
      }
      if (objUser.id) {
        resp = await editUser(objUser);
      } else {
        resp = await createUser(objUser);
      }

      await getUser();

      store.commit("hideLoader");

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
   
      v$.value.$reset();
      cerrar();
    };

    onBeforeUnmount(() => {
      objUser.id = "";
      objUser.name = "";
      objUser.email = "";
      objUser.telefono = "";
      objUser.role_id = 3;
      objUser.password = "";
      objUser.password_confirmation = "";
      objUser.foto = "";
      objUser.status = 1;
      objUser.tmp = "";
      imageUrl.value = null;
      btn.value = false;
    });

    const validarPassword = () => {
      if (objUser.password == "") {
        return true;
      }
      if (
        objUser.password == objUser.password_confirmation &&
        objUser.password != "" &&
        objUser.password_confirmation.length > 3
      ) {
        return true;
      }
      return false;
    };

    const limpiarFrm = () => {
      objUser.id = "";
      objUser.name = "";
      objUser.email = "";
      objUser.telefono = "";
      objUser.role_id = 3;
      objUser.password = "";
      objUser.password_confirmation = "";
      objUser.foto = "";
      objUser.status = 1;
      objUser.tmp = "";
      document.getElementById("name").focus();
      imageUrl.value = null;
    };

    const onChange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        previewImage.value = e.target.result;
        objUser.foto = previewImage.value;
      };
      image.value = file;
      imageUrl.value = URL.createObjectURL(file);
    };

    const cerrar = () => (btn.value = false);

    const cambioClave = () => (resetPass.value = true);

    provide("resetPass",resetPass);

    return {
      objUser,
      v$,
      submitUser,
      validarPassword,
      limpiarFrm,
      onChange,
      foto,
      imageUrl,
      previewImage,
      image,
      cerrar,
      resetPass,
      cambioClave,
      user,
    };
  },
};
</script>

<style scoped>
.userForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 30em;
  margin: auto;
}

.img-user {
  height: 150px !important;
  width: 200px;
}

@media (max-width: 1000px) {
  .userForm {
    width: 100%;
  }
}
</style>
