import { computed } from "vue";
import { useStore } from "vuex";

const useUsuarios = () => {
  const store = useStore();

  const getUser = async () => {
    const resp = await store.dispatch("usuarios/getUser");
    return resp;
  };
  const editUser = async (obj) => {    
    const resp = await store.dispatch("usuarios/editUser", obj);
    return resp;
  };
  const createUser = async (obj) => {
    const resp = await store.dispatch("usuarios/createUser", obj);
    return resp;
  };
  const changePassword = async (obj) => {
    const resp = await store.dispatch("usuarios/changePassword", obj);
    return resp;
  };




  return {
    getUser,
    editUser,
    createUser,
    changePassword,    
    lstUsuarios: computed(() => store.getters["usuarios/getUsuarios"]),
  };
};

export default useUsuarios;
